@import url('https://fonts.googleapis.com/css?family=Ubuntu');

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Ubuntu;
  background: #000;
  color: white;
  width: 100vw;
  height: 100vh;
}

#root {
  height: 100%;
  width: 100%;
}

.router {
  width: 100%;
  max-width: 600px;
  margin: auto;
  height: calc(100% - 60px);
}

.header {
  height: 100px;
  line-height: 100px;
  text-align: center;
  color: #00aced;
  text-transform: uppercase;

}

.content {
  overflow-y: scroll;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content > div {
  width: 100%;
}

.navigation {
  position: fixed;
  bottom: 0;
  left: 5%;
  right: 5%;
  height: 60px;
  line-height: 60px;
  background: #000;
}

.navigation a {
  color: #555;
  font-size: 1.5em;
  display: inline-block;
  width: 20%;
  text-align: center;
  text-decoration: none;
}

.navigation svg {
  color: #555;
  position: relative;
}

.active svg {
  color: #FFF !important;
}

.notification {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: red;
}

#active {
  color: #FFF !important;
}

svg {
  color: #FFF;
}

.calendar-item:first-child > .date {
  color: #FFF;
}

.calendar-item:first-child {
  background: #00aced;
}

.calendar-item {
  width: 90%;
  background: #FFF;
  margin: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.date {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 45px;
  line-height: 45px;
  font-size: 1.2em;
  box-sizing: border-box;
  padding-left: 20px;
  color: #000;
  font-weight: bold;
}

.event {
  min-height: 100px;
  border-radius: 6px;
  background: #FFF;
  box-sizing: border-box;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.time {
  width: 100px;
  height: 60px;
  float: left;
  line-height: 30px;
  font-size: 1.2em;
  text-align: center;
  box-sizing: border-box;
}

.start-time {
  color: #000;
}

.end-time {
  color: #555;
}

.event-name {
  min-height: 60px;
  flex: 1;
  line-height: 30px;
  font-size: 1.2em;
  box-sizing: border-box;
  padding-left: 10px;
  color: #555;
  border-left: 1px solid #777;
  margin-bottom: 20px;
}

.event-name h3 {
  font-weight: normal;
  font-size: 1.4em;
  color: #000;
}

.addToCalendarRoot {
  width: 65px;
  text-align: center;
  margin: 0 20px;
}

.addToCalendar {
  color: #777;
  cursor: pointer;
  width: 50px;
}

.addToCalendarDropdown a {
  text-decoration: none;
  line-height: 30px;
  color: black;
}

.addToCalendarDropdown a:hover {
  cursor: pointer;
  color: #777;
}

.addToCalendarDropdown {
  position: absolute;
  background: white;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  margin-top: -40px;
  margin-left: -10px;
  padding: 10px;
  border-radius: 5px;
}

.social {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.socialnav {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  padding: 0 5%;
}

.socialnavLink {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  text-decoration: none;
  font-size: 1em;
  color: #000;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 2px solid transparent;
}

.socialnavLink.active {
  border-bottom: 2px solid #00ACED;
}

#twitter {
  flex: 1;
  width: 100%;
  max-width: 720px;
  padding-bottom: 60px;
  overflow-y: scroll;
}

#twitter > div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.sponsors {
  width: 90%;
  margin: auto;
}

.sponsor {
  margin-bottom: 20px;
}

.sponsor-image {
  width: 100%;
  padding: 20px;
  background: #FFF;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: center;box-sizing: border-box;
}

.sponsor-image.blackBackground {
  background: black;
}

.sponsor-image img {
  width: 60%;
}

.sponsor a {
  display: block;
  width: 100%;
  height: 60px;
  background: #00aced;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 60px;
  font-size: 1.2em;
  font-weight: bold;
}

.profile {
  background-image: url('/profile-bg.jpg');
  background-size: cover;
  width: 90%;
  margin: auto;
  color: #FFF;
  height: 230px;
  margin-bottom: 20px;
  position: relative;
}

.profile-arrow {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.8em;
  color: #00aced;
}

.school-arrow {
  top: 35px;
}

.about-arrow {
  font-size: 1.8em;
  color: #00aced;
}

.profile-img {
  float: left;
  padding-top: 130px;
  padding-left: 15px;
  padding-right: 25px;
  width: 70px;
  height: 70px;
}

.profile-img > div {
  height: 100%;
  width: 100%;
  border-radius: 35px;
  overflow: hidden;
  background-image: url('/profile0.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.profile img {
  border-radius: 35px;
  height: 70px;
  width: 70px;
}

.profile h1 {
  padding-top: 150px;
  font-size: 1.8em;
  text-transform: uppercase;
}

.driving-school {
  background: #FFF;
  width: 90%;
  margin: auto;
  color: #000;
  min-height: 250px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
}

.driving-school h2 {
  text-transform: uppercase;
  font-size: 1.3em;
  color: #00aced;
  padding-top: 20px;
  padding-bottom: 20px;
}

.rtrc-container {
  display: flex;
  flex-wrap: wrap;
}

.rtrc-container a {
  width: 50%;
  color: #FFF;
  text-decoration: none;
  font-size: 1.6em;
  min-height: 150px;
}

.rtrc-link {
  margin: 8px;
  position: relative;
  height: calc(100% - 8px);
}

.rtrc-link img {
  width: 100%;
  display: block;
}

.shader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.4);
}

.shader.references {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/references/toomas_heikkinen.jpg');
  background-size: cover;
  background-position: center;
}

.shader.consulting {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/profile.jpg');
  background-size: cover;
  background-position: center;
}

.rtrc-link p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  font-size: 0.75em;
}

.references-container {
  padding: 0 10px;
  margin-bottom: 70px;
}

.references-image-toomas {
  background: url('/references/toomas_heikkinen.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-top: 56%;
}

.references-image-nicholas {
  background: url('/references/nicholas_latifi.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-top: 56%;
}

.references-image-miko {
  background: url('/references/miko_marczyk.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-top: 56%;
}

.references-image-miko {
  background: url('/references/miko_marczyk.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-top: 56%;
}

.references-name {
  margin-top: 10px;
}

.references-title {
  color: #BBBBBB;
}

.references-quote {
  font-size: 1.2em;
  margin-top: 20px;
  font-style: italic;
}

.contact-container {
  display: flex;
  flex-direction: column;
}

.contact-container label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.contact-container input, .contact-container textarea {
  margin-top: 5px;
  outline: none;
  padding: 10px 10px 7px 10px;
  border-radius: 5px;
  border: none;
  border-bottom: 3px solid white;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.contact-container input:focus, .contact-container textarea:focus {
  border-bottom: 3px solid #00ACED;
}

.contact-container select {
  margin-top: 5px;
  height: 33px;
  outline: none;
  border: none;
}

.contact-send-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.contact-send-container > a {
  padding: 20px;
  background: #00ACED;
  width: 100px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  color: white;
}

.contact-send-container > a:hover {
  background: rgb(1, 124, 169);
}

.about-profile {
  padding: 8%;
  margin-bottom: 20px;
}

.name-bd {
  float: left;
}

.about-profile button {
  float: right;
  border: none;
  background: #00aced;
  color: #FFF;
  font-family: Ubuntu;
  height: 60px;
  text-transform: uppercase;
  font-weight: bold;
  width: 120px;
  border-radius: 6px;
  line-height: 1.3em;
}

.about-arrow {
  position: absolute;
  top: 20px;
  left: 30px;

}

.about-profile p {
  color: #00aced;
  font-size: 1.1em;
}

.about-header-img {
  width: 100%;
  height: 150px;
  background: url('/profile.jpg');
  background-size: cover;
  background-position: center;
}

.about-info {
  padding: 8%;
  font-size: 1.1em;
  line-height: 1.8em;
}

.about-info h3 {
  color: #00aced;
  text-transform: uppercase;
}

ul {
  list-style: none;
}

.setting {
  width: 90%;
  margin: auto;
  box-sizing: border-box;
  background: #FFF;
  color: #000;
  margin-bottom: 20px;
  height: 80px;
  line-height: 80px;
  padding-left: 30px;
  padding-right: 30px;
}

.setting svg {
  color: #000;
  padding-right: 15px;
}

.setting h3 {
  float: left;
}

.setting p {
  color: #666;
  font-size: 1.1em;
  float: right;
}
